.graph {
  position: relative;
  margin-top: $article-padding * 2;
  margin-bottom: $article-padding * 2;
  background: rgba(255,255,255,.2);

  > div {
    transition: all .5s ease;
  }

  .bar {
    height: $graph-bar-height;
  }


  .clinton .bar {
    @include bar-gradient($clinton-gradient-from, $clinton-gradient-to);
  }

  .sanders .bar {
    @include bar-gradient($sanders-gradient-from, $sanders-gradient-to);
  }

  &.graph--half-marker::before {
    content: '';
    height: 6em;
    border-left: 4px dotted $gray-lighter;
    position: absolute;
    top: -1em;
    left: 50%;
    margin-left: -2px;
    z-index: 2;
  }

  .number {
    font-size: 1.1428em;
    line-height: 1.5rem;
    text-align: center;
    margin-top: .25em;
  }

  .winner .number {
    font-size: 1.7142em;
    font-weight: bold;
    color: $gray-dark;
  }

}

.flex-bar--horiz,
.flex-bar--horizontal,
.flex-bar--vert,
.flex-bar--vertical{
  display: flex;
  border-radius: $border-radius;

  > div:first-child .bar,
  > div.bar:first-child {
    border-radius: $border-radius 0 0 $border-radius;
  }

  > div:last-child .bar,
  > div.bar:last-child {
    border-radius: 0 $border-radius $border-radius 0;
  }
}

.graph--center-out {
  @include clearfix;
  background: rgba(255,255,255,.2);
  border-radius: $border-radius;
  margin-bottom: $article-padding * 2;

  .left,
  .right {
    position: relative;
    height: $graph-bar-height - .5em;
    width: 50%;
    float: left;
    align-items: center;

    .bar {
      height: $graph-bar-height + .5em;
    }
  }

  .number {
    margin-top: 0;
  }

  .total {
    display: none;
  }

  .value {
    position: absolute;
    top: 125%;
    width: 100%;
    left: 0;
    text-align: center;
    line-height: .8em;
    font-size: em(29);

    .percent {
      font-size: .6em;
      letter-spacing: -.04em;
      opacity: .6;
    }

    span {
      display: block;
    }
  }

  .left {
    justify-content: flex-end;

    .inner {
      right: 0;
    }
  }

  .right {
    .inner {

    }
  }

}

@media (min-width: $screen-xs-min) {
  .graph--center-out {
    width: 90%;
    margin-left: auto;
    margin-right: auto;

    .total {
      display: block;
      font-size: em(16);
      font-weight: bold;
      color: rgba(255,255,255,.75);
      position: absolute;
      top: 50%;
      margin-top: - em(8);
    }

    .bar {
      position: relative;
    }

    .value {
      width: auto;
      top: 110%;
    }

    .left {
      .value {
        left: 0;
        text-align: left;
      }

      .total {
        left: -$graph-outside;
        text-align: left;
      }
    }

    .right {
      .value {
        text-align: right;
        right: 0;
      }

      .total {
        left: auto;
        right: -$graph-outside;
        text-align: right;
      }
    }
  }
}



graph-flex-bar[click] .bar {
  cursor: pointer;
}