#latest {

  .staterow-state {

    candidate-image {
      position: absolute;
      top: 0;
      right: 0;

      img {
        font-size: 7.25em;
        width: 2.75em;
        margin-right: -.5em;
      }
    }

    .delegates {
      margin: 0 auto;

      th {
        font-weight: normal;
        padding: 0 .25em;
      }

      .winner {
        font-weight: bold;
      }
    }

    @media (min-width: $screen-xs-min) {
      candidate-image{
        z-index:2;

        img {
          max-width: 105px;
          margin-right: -.25em;
        }
      }
    }

  }

}