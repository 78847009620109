$republican: #BC4444;
$democrat: #0071BC;
$swing: #46BC44;

$darken-amount: 10%;
$republican-stroke: darken($republican, $darken-amount);
$democrat-stroke: darken($democrat, $darken-amount);
$swing-stroke: darken($swing, $darken-amount);

$icon-font-path: 'fonts/bootstrap/';
$font-family-sans-serif:  Play, "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-size-medium: em(16);
$headings-font-weight: bold;

$link-color: #2794E6;

$clinton-gradient-from: rgb(37,77,163);
$clinton-gradient-to: rgb(32,66,140);
$sanders-gradient-from: rgb(39,148,230);
$sanders-gradient-to: rgb(8,126,215);
$orange-gradient-from: rgb(255,147,30);
$orange-gradient-to: rgb(242,139,30);

$article-padding: 2em;
$content-width: 1024px;
$border-radius: 2px;

$black: #000;

$articles: (
  white: (
    gradient-from: rgb(124,124,124),
    gradient-to: rgb(230,230,230),
    star-opacity: .26,
    header-color: #002E4F,
    header-shadow-color: #ffffff,
    text-color: #808080
  ),
  whiteLighter: (
    gradient-from: rgb(175,175,175),
    gradient-to: rgb(230,230,230),
    star-opacity: .26,
    header-color: #002E4F,
    header-shadow-color: #ffffff,
    text-color: #808080
  ),
  green: (
    gradient-from: rgb(72,138,71),
    gradient-to: rgb(88,196,84),
    star-opacity: .12,
    header-color: #ffffff,
    header-shadow-color: #086800,
    text-color: #EDF5F9
  ),
  blue: (
    gradient-from: rgb(0,45,86),
    gradient-to: rgb(11,94,156),
    star-opacity: .05,
    header-color: #ffffff,
    header-shadow-color: #002E4F,
    text-color: #EDF5F9
  ),
  red: (
    gradient-from: rgb(128,26,30),
    gradient-to: rgb(193,39,45),
    star-opacity: .06,
    header-color: #ffffff,
    header-shadow-color: #6B0000,
    text-color: #EDF5F9,
  ),
  footer: (
    gradient-from: lighten($black, 13.5%),
    gradient-to: lighten($black, 20%),
    star-opacity: .05,
    header-color: #ffffff,
    header-shadow-color: #aaa,
    text-color: lighten($black, 46.7%),
  )
);

$candidate-header-multiplier: 1.3;
$candidate-image-width: 15rem;
$candidate-image-width-winner: 18rem;
$candidate-image-red: rgb(237,28,36);
$candidate-image-white: rgb(242,242,242);
$candidate-image-blue: rgb(0,113,188);
$candidate-image-borders: (
  (.04191em, $candidate-image-red),
  (.0598em, $candidate-image-white),
  (.1197em, $candidate-image-blue)
);
$candidate-image-header-width: $candidate-image-width * $candidate-header-multiplier;
$candidate-image-header-around: (-$candidate-image-header-width / 2) - 2.5rem;

$graph-bar-height: 4em;
$graph-outside: 2.5em;
$graph-bar-spacing: .25em;


$modal-graph-height: $graph-bar-height;
$modal-details-color: rgb(179,179,179);
$modal-backdrop-bg: #333333;
$modal-backdrop-opacity: .8;

$modal-arrow-size: 1.2em;
$modal-arrow-margin: $modal-arrow-size * 3;
$modal-arrow-opacity: .6;
$modal-arrow-opacity-hover: .8;