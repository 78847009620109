@font-face {
    font-family: 'Day Poster Black';
    src: url('fonts/dayposterblack/DAYPBL__-webfont.eot');
    src: url('fonts/dayposterblack/DAYPBL__-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/dayposterblack/DAYPBL__-webfont.woff') format('woff'),
         url('fonts/dayposterblack/DAYPBL__-webfont.ttf') format('truetype'),
         url('fonts/dayposterblack/DAYPBL__-webfont.svg#dayposterblackregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

