@mixin borders($borders) {
  $all: ();
  @each $width, $color in $borders {
    $all: append($all, 0 0 0 $width $color);
  }
  box-shadow: zip($all);
}

candidate-image {
  position: relative;

  img {
    @include borders($candidate-image-borders);
    max-width: 45%;
    width: $candidate-image-width;
    font-size: $candidate-image-width;
    border-radius: 50%;
    overflow: hidden;
    transition: all .5s ease;
  }

  &.winner img {
    width: $candidate-image-width-winner;
    font-size: $candidate-image-width-winner;
  }
}

header.candidate-images {
  margin-top: $candidate-image-header-around * .8;
  margin-bottom: $candidate-image-header-width / 6;

  @media (min-width: $screen-sm-min){
    margin-top: $candidate-image-header-around;
  }

  candidate-image img {
    width: $candidate-image-header-width;
    font-size: $candidate-image-header-width * .8;

    @media (min-width: $screen-sm-min){
      font-size: $candidate-image-header-width;
    }
  }
}

candidate-image + candidate-image img {
  margin-left: .1795em;
}