.map-controls {
  margin-top: 2em;

  @media(min-width: $screen-xs-min){
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 2em 6em 0 6em;
  }


  section {
    + section {
      margin-top: 2em;
    }

    .btn-group {
      padding: 0 1em;

      label {
        display: inline-block;
        position: relative;
        cursor: pointer;
      }

      label > .marker {
        @include bar-gradient($orange-gradient-from, $orange-gradient-to);
        display: block;
        position: absolute;
        left: 50%;
        width: 0;
        height: 1em;
        transition: all .25s ease;
        border-radius: 2px;
      }

      label.active > .marker {
        width: 100%;
        margin-left: -50%;
      }

      label + label{
        margin-left: .5em;
      }
    }

    footer {
      border-top: 1px solid #ccc;
      padding-top: .7em;

      h1 {
        color: #808080;
        font-size: .875em;
        text-transform: uppercase;
      }
    }
  }
}