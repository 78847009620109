//
// Heading Overrides
//
// Each heading is a bit different and the <small> is what should be centered on
// not just a generic text-align:center so put all the necessary margin tweaks here
//

@media(min-width: $screen-sm-min) {
  #map > .content > header h1 {
    margin-left: 1.35em;

    small {
      margin-top: -.2em;
    }
  }

  #delegates > .content > header h1 {
    margin-left: -.32em;
  }

  #latest > .content > header h1 {
    margin-left: -.4em;
  }

  #outstanding > .content > header h1 {
    margin-left: .65em;
  }

  #next > .content > header h1 {
    margin-left: -.65em;
  }
}