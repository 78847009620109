#outstanding {

  .graph {
    margin-top: $article-padding * 4;
    margin-bottom: $article-padding * 3.5;
    box-shadow: 0 0 8px 2px rgba(0,0,0,.1);

    .flex-bar-content {
      position: relative;
    }

    .state-data {
      position: absolute;
      top: -4em;
      font-weight: normal;
      width: 2.5em;
      left: -1.125em;

      .state-data-abbv {
        font-size: em(32);
        opacity: .8;
      }
    }

    .bar {
      display: flex;
      justify-content: center;
      border-right: 1px solid;

      &:last-child {
        border: none;
      }

      &:nth-child(even) .state-data{
        top: auto;
        bottom: -4em;
      }

      &:nth-child(odd) .state-data::after,
      &:nth-child(even) .state-data::before {
        content: '';
        display: block;
        position: relative;
        height: 12px;
        top: 2px;
        left: 50%;
        border-left: 1px solid white;
        opacity: .15;
      }

      &:nth-child(even) .state-data::before {
        top: -2px;
      }
    }

    .state--democrat {
      background: $democrat;
      border-color: $democrat-stroke;
    }

    .state--republican {
      background: $republican;
      border-color: $republican-stroke;
    }

    .state--swing {
      background: $swing;
      border-color: $swing-stroke;
    }
  }

  .totals{

    h1 {
      font-size: em(60);
    }

    h2 {
      font-size: 25px;
      opacity: .6;
      font-weight: normal;
    }
  }

  .breakdown {
    margin-top: -1em;
    position: relative;

    section {
      box-sizing: content-box;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      width: 10rem;
      height: 10rem;
      border-radius: 50%;
      border-style: solid;

      &.democrat {
        background-color: $democrat;
        border-color: $democrat;
      }

      &.republican {
        background-color: $republican;
        border-color: $republican;
      }

      &.swing {
        background-color: $swing;
        border-color: $swing;
      }

      .breakdown-total {
        font-size: em(24);
        line-height: .9em;
      }

      .breakdown-percent {
        font-size: 1em;
        opacity: .8;
      }
    }
  }
}